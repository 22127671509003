$(function() {
    
    
    setTimeout(function(){
       // alert(2)
        AppleID.auth.init({
            clientId : 'com.iconparkingredemption',
            scope : 'email name openid',
            redirectURI : 'https://redeemmonthly.iconparking.com',
            //state : 'DE',
            nonce : 'abv',
            usePopup : true //or false defaults to false
        });
    },10000)
    
document.addEventListener('AppleIDSignInOnSuccess', function(data) {
localStorage.setItem('appleLogin',JSON.stringify(data.detail))  

    //location.reload();    
});


// for swiper configration 
var pathname = window.location.pathname;
// $('.mg-space-init').mgSpace({
//      // Breakpoints at which the accordian changes # of columns
//      breakpointColumns: [
//          {
//              breakpoint: 0,
//              column: 1
//          },
//          {
//              breakpoint: 768,
//              column: 2
//          },
//          {
//              breakpoint: 1025,
//              column: 3
//          },
//          {
//              breakpoint: 1400,
//              column: 3
//          }
//      ],
//      rowWrapper: ".mg-rows",
//      row: ".mg-row",
//      targetWrapper: ".mg-targets",
//      target: ".mg-target",
//      trigger: ".mg-trigger",
//      close: ".mg-close",
//      rowMargin: 30,
//      targetPadding: 0,
//      useHash: false,
//      useOnpageHash: false,
//      hashTitle: "#/item-",
//      useIndicator: true
// });
});
$(document).on('input, textarea', function() {
setTimeout(function() {
window.scrollTo(document.body.scrollLeft, document.body.scrollTop);
}, 0);
});
$(document).on('focus', 'input, textarea', function(e) {
$(this).parents('.form-group').addClass('focused');
});
$(document).on('focus', 'body', function(e) {
// setTimeout(function() {
// $('.mg-space-init').mgSpace();

// }, 300);

});
$(document).on('blur', 'input, textarea, select', function(e) {
if ($(this).val().length < 1) {
$(this).parents('.form-group').removeClass('focused');
}
});
$(document).on('click', '.dropdown-menu-right', function(e) {
e.stopPropagation();
});

$(document).on('click', '#collapsBtn', function(e) {
$(this).parent().toggleClass('hideElement');
});

$(document).on('click', '.crossBtn', function(e) {
$(this).parent().parent().toggleClass('open');
});

$(document).on('click', 'input.sdate', function() {
setTimeout(function() {
$('timepicker').find('input').each(function() {
    $(this).attr('readonly', true);
});
if (!$('span.disabled').last().parent().next().find('span').hasClass('selected')) {
    $('span.disabled').last().parent().next().find('span').css('color', '#ED8222');
    $('span.disabled').last().parent().next().find('span').on('blur', function() {
        $(this).css('color', '#ED8222');
    });
}
}, 50);
});
$(document).on($.debounce(200, function() {
$('input:-webkit-autofill').each(function() {
if ($(this).val().length !== "") {
    $(this).parents('.form-group').addClass('focused');
}
});
}));
$(document).on('click', '.dropdown-menu-right', function(e) {
e.stopPropagation();
});

$(document).on('click', '#collapsBtn', function(e) {
$(this).parent().toggleClass('hideElement');
});

$(document).on('click', '.crossBtn', function(e) {
$(this).parent().parent().toggleClass('open');
});

// $(document).on('click', 'label.sortByDistance', function(){
//  //$(".facilityList2").html($(".facilityList1").html())// write 2
//  $(".facilityList2").each(function(){
//      $(this).html($(this).children('li').sort(function(a, b){
//          var aPrice = $(a).find('p.facDistance').text();
//          var bPrice = $(b).find('p.facDistance').text();
//          return (bPrice) < (aPrice) ? 1 : -1;
//      }));
//  });

// });

// $(document).on('click', 'label.sortByPrice', function(){
//  //$(".facilityList2").html($(".facilityList1").html())// write 2
//  $(".facilityList2").each(function(){
//      $(this).html($(this).children('li').sort(function(a, b){
//          var aPrice = $(a).find('p.facPrice').text();
//          var bPrice = $(b).find('p.facPrice').text();
//          return (bPrice) < (aPrice) ? 1 : -1;
//      }));
//  });
// });

// $(document).on('click', '.monthlySort label.sortByDistance', function(){
//  //$(".facilityList2").html($(".facilityList1").html())// write 2
//  $(".facilityList1").each(function(){
//      $(this).html($(this).children('li').sort(function(a, b){
//          var aPrice = $(a).find('p.facDistance').text();
//          var bPrice = $(b).find('p.facDistance').text();
//          return (bPrice) < (aPrice) ? 1 : -1;
//      }));
//  });

// });

// $(document).on('click', '.monthlySort label.sortByPrice', function(){
//  //$(".facilityList2").html($(".facilityList1").html())// write 2
//  $(".facilityList1").each(function(){
//      $(this).html($(this).children('li').sort(function(a, b){
//          var aPrice = $(a).find('p.facPrice').text();
//          var bPrice = $(b).find('p.facPrice').text();
//          return (bPrice) < (aPrice) ? 1 : -1;
//      }));
//  });
// });

function copytext(texttocopyid, anchorid) {

var copyText = document.getElementById(texttocopyid);
copyText.select();
document.execCommand("Copy");
$('#' + anchorid).text('COPIED');

}

function myFunction() {
$('.navbar,.menuToggle,.listBtn').css('display', 'none');
$('.searchFilter.hidden-xs').css('margin-top', '0px');
$('.SearchPanelMobile').css('top', '15px')
$('footer').css('display', 'none')
}

function inIframe() {
try {
if (window.self !== window.top) {
    setTimeout(myFunction, 3000);

}
} catch (e) {

}
}
//inIframe();

function GetToken() {
localStorage.removeItem('card_tokenHard');
localStorage.removeItem('card_token');
localStorage.removeItem('card_type');
(function(document, Heartland) {
// Enhance the payment fields
Heartland.Card.attachNumberEvents('#standardCardNumber');
Heartland.Card.attachExpirationEvents('#standardCardExpiration1');
Heartland.Card.attachCvvEvents('#standardCardCvv');

// Grab references to the field elements
var card = document.getElementById('standardCardNumber');
var expiration = document.getElementById('standardCardExpiration1');
var cvv = document.getElementById('standardCardCvv');

if (expiration.value !== undefined && expiration.value != '' && card.value !== undefined && card.value != '' && cvv.value !== undefined && cvv.value != '') {
    // Parse the expiration date
    var split = expiration.value.split('/');
    var month = split[0].replace(/^\s+|\s+$/g, '');
    var year = split[1].replace(/^\s+|\s+$/g, '');
    try {
        // Create a new `HPS` object with the necessary configuration
        // pkapi_cert_SJSw9pbjrXfNbEB417 // test
        // pkapi_prod_7Fu9Olem2Eu3SgGvVg //live
        (new Heartland.HPS({
            publicKey: 'pkapi_prod_7Fu9Olem2Eu3SgGvVg',
            cardNumber: card.value.replace(/\D/g, ''),
            cardCvv: cvv.value.replace(/\D/g, ''),
            cardExpMonth: month.replace(/\D/g, ''),
            cardExpYear: '20' + year.replace(/\D/g, ''),
            // Callback when a token is received from the service
            success: function(resp) {
                localStorage.setItem('card_tokenHard', '1');
                localStorage.setItem('card_token', resp.token_value);
                localStorage.setItem('card_type', resp.card_type);
            },
            // Callback when an error is received from the service
            error: function(resp) {
                    localStorage.setItem('card_tokenHard', '1');
                    // $('#ErrorContainer').html('<div class="alert alert-danger alert-dismissible"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> <strong>Sorry!</strong> '+resp.error.message+'</div>').show();
                    return false;
                }
                // Immediately call the tokenize method to get a token
        })).tokenize();
    } catch (e) {
        // $('#ErrorContainer').html('<div class="alert alert-danger alert-dismissible"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> <strong>Sorry!</strong> There was an issue submitting. Are all of the fields filled out?</div>').show();
        return false;
    }
}
}(document, Heartland));
}

function myMethod(id) {

var element = $('#printable');
var getCanvas;
html2canvas(element, {
allowTaint: true,
logging: true,
onrendered: function(canvas) {
    $('div#printable').html(canvas)
        //$("#previewImage").append(canvas);
    getCanvas = canvas;
    window.print();
}
});
}

function GetPaymentCardToken() {
if ($('#AddCardIcon').prop("checked") == true) {
(function(document, Heartland) {

    // Enhance the payment fields
    Heartland.Card.attachNumberEvents('#standardCardNumber');
    Heartland.Card.attachExpirationEvents('#standardCardExpiration1');
    Heartland.Card.attachCvvEvents('#standardCardCvv');

    // Grab references to the field elements
    var card = document.getElementById('standardCardNumber');
    var expiration = document.getElementById('standardCardExpiration1');
    var cvv = document.getElementById('standardCardCvv');
    if (expiration.value !== undefined && expiration.value != '' && card.value !== undefined && card.value != '' && cvv.value !== undefined && cvv.value != '') {
        // Parse the expiration date
        var split = expiration.value.split('/');
        var month = split[0].replace(/^\s+|\s+$/g, '');
        var year = split[1].replace(/^\s+|\s+$/g, '');

        try {
            // pkapi_cert_SJSw9pbjrXfNbEB417  // test
            // pkapi_prod_7Fu9Olem2Eu3SgGvVg //live
            // Create a new `HPS` object with the necessary configuration
            (new Heartland.HPS({
                publicKey: 'pkapi_prod_7Fu9Olem2Eu3SgGvVg',
                cardNumber: card.value.replace(/\D/g, ''),
                cardCvv: cvv.value.replace(/\D/g, ''),
                cardExpMonth: month.replace(/\D/g, ''),
                cardExpYear: '20' + year.replace(/\D/g, ''),
                // Callback when a token is received from the service
                success: function(resp) {
                    localStorage.setItem('card_token', resp.token_value);
                    localStorage.setItem('card_type', resp.card_type);
                },
                // Callback when an error is received from the service
                error: function(resp) {
                        // $("#AddCardIcon").prop("checked", false);
                        // $('#ErrorContainer').html('<div class="alert alert-danger alert-dismissible"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> <strong>Sorry!</strong> '+resp.error.message+'</div>').show();
                        return true;
                    }
                    // Immediately call the tokenize method to get a token
            })).tokenize();
        } catch (e) {
            // $("#AddCardIcon").prop("checked", false);
            // $('#ErrorContainer').html('<div class="alert alert-danger alert-dismissible"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> <strong>Sorry!</strong> There was an issue submitting. Are all of the fields filled out?</div>').show();
            return true;
        }
    }
}(document, Heartland));

} else {
localStorage.removeItem('card_token');
localStorage.removeItem('card_type');
}
}

$(document).on('click', '.close-badge', function() {
// alert("hey man");
$('.contentBar').css("height", "calc(100vh - 100px)");
$('.campians_list').removeClass('isLoyalyBadge');
});
